/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Typography,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  keyframes,
  styled
} from '@mui/material';
import LogOut from '../../logOut';
import { useAuthDataContext } from '../../../contexts/AuthContext';

const ripple = keyframes`
  0% {
    transform: scale(.8);
    opacity: 1;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
`;

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: `${ripple} 1.2s infinite ease-in-out`,
      border: '1px solid currentColor',
      content: '""'
    }
  }
}));

const AvatarBadge = ({ currentUser }) => {
  const initials =
    currentUser?.firstName && currentUser?.lastName
      ? `${currentUser.firstName.charAt(0)}${currentUser.lastName.charAt(0)}`
      : currentUser?.firstName
        ? currentUser.firstName.charAt(0)
        : currentUser?.lastName
          ? currentUser.lastName.charAt(0)
          : '';

  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      badgeContent=" ">
      <Avatar
        variant="rounded"
        sx={{
          marginRight: '5px',
          backgroundColor: '#E05829',
          textTransform: 'uppercase'
        }}>
        {initials}
      </Avatar>
    </StyledBadge>
  );
};

const HeaderUserbox = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState({});
  const user = useAuthDataContext();

  useEffect(() => {
    if (user) {
      setCurrentUser({
        firstName: user.authInfo?.firstName,
        lastName: user.authInfo?.lastName
      });
    }
  }, [user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    LogOut();
  };
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        className="ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
        disableRipple>
        <div className="d-block p-0 avatar-icon-wrapper">
          <AvatarBadge currentUser={currentUser} />
        </div>

        <div className="d-none d-xl-block pl-2">
          <div className="font-weight-bold pt-2 line-height-1">
            {currentUser?.firstName && currentUser?.lastName
              ? `${currentUser.firstName} ${currentUser.lastName}`
              : currentUser?.firstName
                ? `${currentUser.firstName}`
                : currentUser?.lastName
                  ? `${currentUser.lastName}`
                  : ''}
          </div>
          <span className="text-black-50">{t('admin')}</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}>
        <div className="dropdown-menu-lg overflow-hidden p-0">
          <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
            <Typography className="text-capitalize pl-1 font-weight-bold text-primary">
              <span>{t('options_de_profil')}</span>
            </Typography>
          </div>
          <List
            component="div"
            className="nav-neutral-primary text-left d-flex align-items-center flex-column px-3 pb-3">
            <ListItem
              onClick={handleLogOut}
              button
              className="d-block text-left mt-2">
              {t('logout')}
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

export default HeaderUserbox;
