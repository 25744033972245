import axios from 'axios';
import { toast } from 'react-toastify';
import { tokenKey } from '../constants/storageKeys';

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.withCredentials = true;
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    toast.error('An unexpected error occurrred.');
  }

  return Promise.reject(error);
});

const token = localStorage.getItem(tokenKey);
// eslint-disable-next-line dot-notation
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete
};
