import { jwtDecode } from 'jwt-decode';
import { loginUrl } from '../constants/api';
import { adminRole } from '../constants/dbDefaults';
import { tokenKey } from '../constants/storageKeys';
import http from './httpService';

export async function login(email, password) {
  const { data: jwt } = await http.post(loginUrl, {
    email,
    password
  });
  const decoded = jwtDecode(jwt.access_token);
  if (decoded && decoded.role === adminRole.MYDPOADMIN) {
    return localStorage.setItem(tokenKey, jwt?.access_token);
  }
  throw new Error();
}

export async function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getAccessToken() {
  try {
    return localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
  } catch (ex) {
    return null;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  logout,
  getCurrentUser,
  getAccessToken
};
