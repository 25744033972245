import { Drawer, styled } from '@mui/material';

const drawerWidth = 240;

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: open ? drawerWidth : '60px',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  backgroundColor: '#253053',
  color: '#AFB7C7',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen
  }),
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : '60px',
    overflowX: 'hidden',
    backgroundColor: '#253053',
    color: '#AFB7C7',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: open
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen
    })
  }
}));
