export const home = '/';
export const newFunctionnality = '/new-functionnality';
export const login = '/login';
export const resetPassword = '/reset-password';
export const recoverPassword = '/forgot-password';
export const logout = '/logout';
export const companies = '/companies';
export const consultants = '/consultants';
export const groups = '/groups';
export const repositoryManagement = '/repository-management';
export const history = '/history';
export const adminsManagement = '/admins-management';
export const comingSoon = '/coming-soon';
export const toolbox = '/toolbox';
export const articles = '/articles';
export const historyIA = '/history-ia';
export const conformityLink = '/conformity';
