import { lazy } from 'react';

export const privateRoutes = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('../containers/home'))
  },
  {
    path: '/new-functionnality',
    exact: true,
    component: lazy(() => import('../containers/newFunctionnality'))
  },
  {
    path: '/companies',
    exact: true,
    component: lazy(() => import('../containers/companies'))
  },
  {
    path: '/consultants',
    exact: true,
    component: lazy(() => import('../containers/consultants'))
  },
  {
    path: '/groups',
    exact: true,
    component: lazy(() => import('../containers/groups'))
  },
  {
    path: '/admins-management',
    exact: true,
    component: lazy(() => import('../containers/adminsManagement'))
  },
  {
    path: '/history',
    exact: true,
    component: lazy(() => import('../containers/history'))
  },
  {
    path: '/repository-management',
    exact: true,
    component: lazy(() => import('../containers/repositoryManagement'))
  },
  {
    path: '/not-found',
    exact: true,
    component: lazy(() => import('../containers/notFound'))
  },
  {
    path: '/coming-soon',
    exact: true,
    component: lazy(
      () => import('../containers/noAccess/NoAccessDevInProgress')
    )
  },
  {
    path: '/toolbox',
    exact: true,
    component: lazy(() => import('../containers/toolbox'))
  },
  {
    path: '/articles',
    exact: true,
    component: lazy(() => import('../containers/articles'))
  },
  {
    path: '/history-ia',
    exact: true,
    component: lazy(() => import('../containers/historyIA'))
  },
  {
    path: '/conformity',
    exact: true,
    component: lazy(() => import('../containers/conformity'))
  }
];

export const publicRoutes = [
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('../containers/login'))
  },
  {
    path: '/logout',
    exact: true,
    component: lazy(() => import('../containers/logOut'))
  },
  {
    path: '/reset-password',
    exact: false,
    component: lazy(() => import('../containers/resetPassword'))
  },
  {
    path: '/forgot-password',
    exact: false,
    component: lazy(() => import('../containers/recoverPassword'))
  },
  {
    path: '/not-found',
    exact: true,
    component: lazy(() => import('../containers/notFound'))
  }
];
