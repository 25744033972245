export const roles = {
  CONSULTANT: 'CONSULTANT',
  DPO: 'DPO',
  COLLABORATOR: 'COLLABORATOR',
  OPERATIONAL_MANAGER: 'OPERATIONAL_MANAGER',
  TRAINESS: 'TRAINESS',
  DIRECTOR: 'DIRECTOR',
  READ_ONLY: 'READ_ONLY',
  GDPR_CORRESPONDENT: 'GDPR_CORRESPONDENT',
  GDPR_CORRESPONDENT_TRANSVERSAL: 'GDPR_CORRESPONDENT_TRANSVERSAL'
};

export const modules = {
  REGISTRY: 'REGISTRY',
  COMPLIANCE: 'COMPLIANCE',
  DOCUMENTATION: 'DOCUMENTATION',
  TRAINING: 'TRAINING',
  PIA: 'PIA',
  REQUESTS: 'REQUESTS',
  VIOLATIONS: 'VIOLATIONS',
  SERVICE_PROVIDERS: 'SERVICE_PROVIDERS'
};

export const organisationStructure = {
  DIRECTION: 'DIRECTION',
  DEPARTMENT: 'DEPARTMENT',
  SERVICE: 'SERVICE'
};

export const packages = {
  LIGHT: 'LIGHT',
  CUSTOM: 'CUSTOM',
  FREEMIUM: 'FREEMIUM',
  TRANSPARENCY: 'TRANSPARENCY',
  TRANQUILITY: 'TRANQUILITY',
  SERENITY: 'SERENITY',
  ZEN: 'ZEN'
};

export const contractCommitmentPeriod = {
  ANNUAL: 'ANNUAL',
  MONTHLY: 'MONTHLY'
};

export const timeUnit = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
};

export const permissions = {
  ADMIN: 'ADMIN'
};

export const registryStatus = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  REFUSED: 'REFUSED',
  PUBLISHED: 'PUBLISHED'
};

export const actionStatus = {
  TODO: 'TODO',
  INPROGRESS: 'IN_PROGRESS',
  PENDING: 'PENDING',
  DONE: 'DONE'
};

export const treatmentTemplateType = {
  MODEL: 'MODEL',
  GENERIC: 'GENERIC',
  ACTIVITY_RELATED: 'ACTIVITY_RELATED'
};

export const adminRole = {
  MYDPOADMIN: 'MYDPOADMIN'
};
