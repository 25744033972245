import React, { Suspense } from 'react';
import './i18n';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthDataProvider from './contexts/AuthContext';

function AppWithCallbackAfterRender() {
  return (
    <Suspense fallback={null}>
      <AuthDataProvider>
        <App />
      </AuthDataProvider>
    </Suspense>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <AppWithCallbackAfterRender />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
