/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { logout, home } from '../../constants/internalLinks';
import './ErrorBoundary.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="eb_center">
          <h3>Ooops, page failed to load</h3>
          <p>please, reload the page</p>
          <button
            type="button"
            className="eb_btn_blue"
            onClick={() => window.location.reload()}>
            Reload
          </button>
          <p>
            or try one of these options:{' '}
            <a className="eb_text_blue" href={home}>
              home page
            </a>{' '}
            or{' '}
            <a className="eb_text_blue" href={logout}>
              logout
            </a>
          </p>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
