/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import HeaderUserbox from './HeaderUserbox';

const NavBar = () => {
  const location = useLocation();
  const locaSplit = location.pathname.split('/').slice(1, 4) ?? [''];
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t(locaSplit.pop())} - MyDPO AdminDashBoard`;
  }, [location]);

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end' }}
        className={clsx('app-header', {
          'app-header--shadow': true,
          'app-header--opacity-bg': true
        })}>
        <div className="app-header--pane">
          <HeaderUserbox />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
