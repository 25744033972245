import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import illustration from '../../assets/images/illustrations/pack4/404.svg';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: 'center' }}>
      <img
        src={illustration}
        className="w-50 mx-auto d-block my-5 img-fluid"
        alt="notfound"
      />
      <h3>{t('la_page_que_vous_cherchiez_n_existe_pas')}</h3>
      <h2>Error 404</h2>
      <Button
        // eslint-disable-next-line no-return-assign
        onClick={() => (window.location = '/')}
        variant="contained"
        color="primary">
        {t('retournez_a_la_page_d_accueil')}
      </Button>
    </div>
  );
};

export default NotFound;
