import React, { createContext, useState, useEffect, useContext } from 'react';
import { getCurrentUser, logout } from '../services/auth';
import { getUserMe } from '../services/users';
import LogOut from '../containers/logOut';
import { adminRole } from '../constants/dbDefaults';

export const AuthDataContext = createContext(null);

const AuthDataProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [sessionExp, setSessionExp] = useState(null);
  const [authInfo, setAuthInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const currentAuthData = getCurrentUser();
    if (
      currentAuthData &&
      currentAuthData.role === adminRole.MYDPOADMIN &&
      currentAuthData.exp > Date.now() / 1000
    ) {
      checkUser();
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
      logout();
    }
    async function checkUser() {
      try {
        const res = await getUserMe();
        if (!res.isActive) throw new Error();
        setIsAuthenticated(true);
        setAuthInfo(res);
        setSessionExp(currentAuthData.exp);
        setIsLoading(false);
      } catch (ex) {
        LogOut();
      }
    }
  }, []);

  const onLogout = () => setIsAuthenticated(false);

  const onLogin = () => setIsAuthenticated(true);

  return (
    <AuthDataContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isLoading,
        isAuthenticated,
        sessionExp,
        authInfo,
        onLogin,
        onLogout
      }}
      {...props}
    />
  );
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
