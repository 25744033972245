/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button,
  CssBaseline
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { StyledDrawer } from './LeftNavMenuStyles';
import LeftNavMenuData from '../../../constants/LeftNavMenuData';
import NavBar from '../NavBar';
import logo from '../../../assets/icons/logo_b.png';
import minimize_g from '../../../assets/icons/minimize_g.svg';
import logo_icon from '../../../assets/icons/logo_icon.png';

export default function LeftNavMenu({ children }) {
  const [open, setOpen] = useState(true);
  const [menuToOpen, setMenuToOpen] = useState('');
  const [isMenuToOpen, setIsMenuToOpen] = useState(false);
  const [menuClicked, setMenuClicked] = useState('');
  const [subMenuClicked, setSubMenuClicked] = useState('');

  useEffect(() => {
    const actWidth = window.innerWidth;
    if (actWidth <= 1024) {
      setOpen(false);
    }
  }, []);

  const location = useLocation();

  const handleMenuClick = (top, sub) => {
    setMenuClicked(top);
    setSubMenuClicked(sub);
  };

  const handleMenuSubOpen = (menu) => {
    setMenuToOpen(menu);
    setIsMenuToOpen(!isMenuToOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const calcIt = window.innerHeight - 30;

  return (
    <div
      style={{
        display: 'flex',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#AFB7C7'
      }}>
      <CssBaseline />
      <StyledDrawer variant="permanent" open={open}>
        <Link
          to="/"
          style={{
            margin: '1rem 2px 1rem 0',
            display: 'flex',
            justifyContent: 'center'
          }}>
          {open ? (
            <img src={logo} alt="logo" width="138px" height="37px" />
          ) : (
            <img src={logo_icon} alt="logo" width="32px" height="30px" />
          )}
        </Link>
        <Divider style={{ background: 'rgba(255,255,255,0.15)' }} />
        <Divider style={{ background: 'rgba(255,255,255,0.15)' }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: calcIt
          }}>
          <List>
            {LeftNavMenuData.map((m) => (
              <li key={m.id}>
                <ListItem
                  style={
                    menuClicked === m.id ||
                    location.pathname === m.link ||
                    location.pathname.split('/')[1] === m.link.split('/')[1]
                      ? { color: 'white' }
                      : { color: '#AFB7C7' }
                  }
                  button
                  key={m.id}
                  component={Link}
                  to={m.link}
                  onClick={() => {
                    handleMenuSubOpen(m.id);
                    handleMenuClick(m.id);
                  }}>
                  <ListItemIcon
                    style={{
                      minWidth: '36px'
                    }}>
                    {menuClicked === m.id ||
                    location.pathname === m.link ||
                    location.pathname.split('/')[1] === m.link.split('/')[1]
                      ? m.iconW
                      : m.iconG}
                  </ListItemIcon>
                  <ListItemText
                    style={open ? {} : { visibility: 'hidden' }}
                    disableTypography
                    primary={m.id}
                  />
                </ListItem>
                <Collapse
                  in={
                    (menuToOpen === m.id && isMenuToOpen) ||
                    location.pathname.split('/')[1] === m.link.split('/')[1]
                  }
                  timeout="auto"
                  unmountOnExit>
                  <List component="div" disablePadding>
                    {m.sub &&
                      m.sub.map((sub) => (
                        <ListItem
                          style={
                            subMenuClicked === sub.id ||
                            location.pathname === sub.link
                              ? { color: 'white' }
                              : { color: '#636B7B' }
                          }
                          button
                          key={sub.id}
                          component={Link}
                          to={sub.link}
                          onClick={() => handleMenuClick(m.id, sub.id)}>
                          <ListItemIcon
                            style={{
                              minWidth: '36px'
                            }}>
                            <img
                              src={
                                subMenuClicked === sub.id ||
                                location.pathname === sub.link
                                  ? sub.iconW
                                  : sub.iconG
                              }
                              alt=""
                            />
                          </ListItemIcon>
                          <ListItemText
                            style={open ? {} : { visibility: 'hidden' }}
                            disableTypography
                            primary={sub.id}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              </li>
            ))}
          </List>
          <div
            style={{
              background: '#2C3658'
            }}>
            <Button
              style={{ padding: '1.5rem 1rem', width: '100%' }}
              color="inherit"
              elevation={0}
              aria-label="drawer-open-close"
              onClick={open ? handleDrawerClose : handleDrawerOpen}>
              {!open && (
                <img
                  style={{ transform: 'rotate(180deg)' }}
                  src={minimize_g}
                  height="24px"
                  width="24px"
                  alt=""
                />
              )}
              {open && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <img src={minimize_g} height="24px" width="24px" alt="" />
                </div>
              )}
            </Button>
          </div>
        </div>
      </StyledDrawer>
      <main
        style={{
          width: '75%',
          flexGrow: 1,
          padding: 0
        }}>
        <NavBar open={open} />
        {children}
      </main>
    </div>
  );
}

LeftNavMenu.propTypes = {
  children: PropTypes.node.isRequired
};
