import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DescriptionIcon from '@mui/icons-material/Description';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import HistoryIcon from '@mui/icons-material/History';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import SchoolIcon from '@mui/icons-material/School';
import BuildIcon from '@mui/icons-material/Build';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ChatIcon from '@mui/icons-material/Chat';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {
  home,
  newFunctionnality,
  companies,
  consultants as consultantsUrl,
  groups,
  repositoryManagement,
  history,
  adminsManagement,
  comingSoon,
  toolbox,
  articles as articlesUrl,
  historyIA,
  conformityLink
} from './internalLinks';

const accueil = {
  id: 'Accueil',
  iconW: <DashboardIcon style={{ color: '#fff' }} />,
  iconG: <DashboardIcon style={{ color: '#AFB7C7' }} />,
  link: home
};
const NewFunctionnality = {
  id: 'Nouvelle fonctionnalité',
  iconW: <AnnouncementIcon style={{ color: '#fff' }} />,
  iconG: <AnnouncementIcon style={{ color: '#AFB7C7' }} />,
  link: newFunctionnality
};

const clients = {
  id: 'Clients',
  iconW: <BusinessIcon style={{ color: '#fff' }} />,
  iconG: <BusinessIcon style={{ color: '#AFB7C7' }} />,
  link: companies
};
const consultants = {
  id: 'Consul. / DPO / RGPDT',
  iconW: <AssignmentIndIcon style={{ color: '#fff' }} />,
  iconG: <AssignmentIndIcon style={{ color: '#AFB7C7' }} />,
  link: consultantsUrl
};
const groupes = {
  id: 'Groupes',
  iconW: <BusinessCenterIcon style={{ color: '#fff' }} />,
  iconG: <BusinessCenterIcon style={{ color: '#AFB7C7' }} />,
  link: groups
};
const formations = {
  id: 'Formations',
  iconW: <SchoolIcon style={{ color: '#fff' }} />,
  iconG: <SchoolIcon style={{ color: '#AFB7C7' }} />,
  link: comingSoon
};
const articles = {
  id: 'Articles',
  iconW: <DescriptionIcon style={{ color: '#fff' }} />,
  iconG: <DescriptionIcon style={{ color: '#AFB7C7' }} />,
  link: articlesUrl
};
const GestionDuReferentiel = {
  id: 'Gestion du référentiel',
  iconW: <DataUsageIcon style={{ color: '#fff' }} />,
  iconG: <DataUsageIcon style={{ color: '#AFB7C7' }} />,
  link: repositoryManagement
};
const projets = {
  id: 'Projets',
  iconW: <FeaturedPlayListIcon style={{ color: '#fff' }} />,
  iconG: <FeaturedPlayListIcon style={{ color: '#AFB7C7' }} />,
  link: comingSoon
};
const boiteOutils = {
  id: 'Boite à Outils',
  iconW: <BuildIcon style={{ color: '#fff' }} />,
  iconG: <BuildIcon style={{ color: '#AFB7C7' }} />,
  link: toolbox
};
const historique = {
  id: 'Historique',
  iconW: <HistoryIcon style={{ color: '#fff' }} />,
  iconG: <HistoryIcon style={{ color: '#AFB7C7' }} />,
  link: history
};
const gestionAdmins = {
  id: 'Gestion des admins',
  iconW: <SupervisorAccountIcon style={{ color: '#fff' }} />,
  iconG: <SupervisorAccountIcon style={{ color: '#AFB7C7' }} />,
  link: adminsManagement
};

const historiqueIA = {
  id: 'myDPOGPT',
  iconW: <ChatIcon style={{ color: '#fff' }} />,
  iconG: <ChatIcon style={{ color: '#AFB7C7' }} />,
  link: historyIA
};

const conformity = {
  id: 'Conformité',
  iconW: <AccountBalanceIcon style={{ color: '#fff' }} />,
  iconG: <AccountBalanceIcon style={{ color: '#AFB7C7' }} />,
  link: conformityLink
};

const navlist = [
  accueil,
  NewFunctionnality,
  clients,
  consultants,
  groupes,
  formations,
  articles,
  GestionDuReferentiel,
  projets,
  boiteOutils,
  historique,
  gestionAdmins,
  historiqueIA,
  conformity
];

export default navlist;
