import { createTheme } from '@mui/material/styles';
import shadows from './shadows';

const bo_white = '#FFFFFF';
const bo_orange = '#DE6028';
const bo_orange_hover = '#E87541';
const bo_orange_active = '#F89466';
const bo_gray = '#F7F9FC';
const bo_gray_hover = '#F0F3F8';
const bo_gray_active = '#E6E9F0';
const dot_gray = '#BDC2CB';
const bo_orange_text = '#E83A3A';
const bo_disabled = '#F0F3F8';
const bo_error = '#FFE6E6';
const bo_black_light = '#1F2127';
const bo_gray_link_water = '#C7CCD8';
const bo_gray_shuttle = '#636B7B';
const bo_pale_red = '#FFF5F6';
const bo_green = '#51C363';
const bo_pale_green = '#F5FFF6';
const bo_flamingo_red = '#F03746';
const bo_blue = '#3C44B1';

const MuiTheme = createTheme({
  palette: {
    common: {
      white: `${bo_white}`,
      orange: `${bo_orange}`,
      orange_hover: `${bo_orange_hover}`,
      orange_active: `${bo_orange_active}`,
      orange_text: `${bo_orange_text}`,
      black_light_text: `${bo_black_light}`,
      gray: `${bo_gray}`,
      gray_light: `${dot_gray}`,
      gray_shuttle: `${bo_gray_shuttle}`,
      gray_link_water: `${bo_gray_link_water}`,
      gray_hover: `${bo_gray_hover}`,
      gray_active: `${bo_gray_active}`,
      pale_red: `${bo_pale_red}`,
      flamingo_red: `${bo_flamingo_red}`,
      green: `${bo_green}`,
      pale_green: `${bo_pale_green}`,
      comp_disabled: `${bo_disabled}`,
      comp_error: `${bo_error}`,
      blue: `${bo_blue}`
    },
    primary: {
      main: '#3c44b1'
    },
    type: 'light',
    grey: {
      300: '#fefefe',
      A100: '#f8f9ff'
    },
    secondary: {
      main: '#4191ff'
    },
    error: {
      main: '#f83245'
    },
    success: {
      main: '#1bc943'
    },
    info: {
      main: '#11c5db'
    },
    warning: {
      main: '#f4772e'
    },
    helpers: {
      primary: '#3c44b1',
      main: 'rgba(25, 46, 91, .035)'
    },
    background: {
      paper: '#fff',
      default: '#F4F5FD'
    },
    contrastThreshold: 3,
    tonalOffset: 0.1
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1100,
      xl: 1381
    }
  },
  mixins: {
    search: {
      width: '100%',
      boxSizing: 'border-box',
      border: '1px solid rgba(122, 123, 151, 0.3)',
      background: `${bo_gray_hover}`,
      borderRadius: '4px',
      paddingTop: '0.7rem',
      paddingBottom: '0.7rem'
    },
    searchWithBackground: {
      backgroundSize: '25px',
      backgroundPosition: '10px 10px',
      backgroundRepeat: ' no-repeat',
      paddingLeft: '3rem',
      outline: 'none'
    },
    card_body: {
      border: `1px solid ${bo_gray_link_water}`,
      borderRadius: '0px 0px 4px 4px',
      minHeight: '5rem',
      padding: '1rem',
      background: `${bo_white}`
    },
    table_head_custom: {
      background: `${bo_gray_hover}`,
      borderBottom: `1px solid ${bo_gray_link_water}`,
      height: '1.8rem',
      paddingLeft: '1rem'
    },
    greenBox: {
      background: `${bo_pale_green}`,
      border: `1px solid ${bo_green}`,
      borderRadius: '4px',
      padding: '1rem'
    },
    redBox: {
      background: `${bo_pale_red}`,
      border: `1px solid ${bo_flamingo_red}`,
      borderRadius: '4px',
      minHeight: '50px',
      padding: '1rem'
    }
  },
  shape: {
    borderRadius: '0.2rem'
  },
  overrides: {
    MuiTab: {
      root: {
        fontWeight: 'bold'
      }
    },
    MuiButton: {
      sizeSmall: {
        padding: '6px 20px',
        fontSize: 14
      },
      outlinedSmall: {
        padding: '6px 20px',
        fontSize: 14
      },
      textSmall: {
        padding: '6px 20px',
        fontSize: 14
      },

      sizeMedium: {
        padding: '10px 22px',
        fontSize: 15
      },
      outlined: {
        padding: '10px 22px',
        fontSize: 14
      },
      text: {
        padding: '10px 22px',
        fontSize: 14
      },

      sizeLarge: {
        padding: '16px 28px',
        fontSize: 16
      },
      outlinedLarge: {
        padding: '16px 28px',
        fontSize: 16
      },
      textLarge: {
        padding: '16px 28px',
        fontSize: 16
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
        padding: '10px 22px',
        fontSize: 14
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#070919',
        padding: '8px 16px',
        fontSize: '13px'
      },
      arrow: {
        color: '#070919'
      }
    }
  },
  typography: {
    fontFamily: ['Heebo', 'sans-serif'].join(','),
    htmlFontSize: 18,
    fontSize: 14,
    comp_title: {
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: '22px',
      lineHeight: '27px',
      color: `${bo_black_light}`,
      height: '52px',
      display: 'flex',
      alignItems: 'center'
    },
    card_body: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      lineHeight: '20px',
      color: `${bo_gray_shuttle}`
    },
    card_body_with_text: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      lineHeight: '20px',
      color: `${bo_black_light}`,
      textAlign: 'left'
    },
    greenBox: {
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '20px',
      color: `${bo_green}`
    },
    redBox: {
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '20px',
      color: `${bo_flamingo_red}`
    },
    dot_menu_text: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      lineHeight: '20px',
      textTransform: 'none',
      color: `${bo_black_light}`
    }
  },
  shadows
});

export default MuiTheme;
