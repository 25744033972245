import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorBoundary from './components/ErrorBoundary';
import { publicRoutes, privateRoutes } from './constants/routes';
import LeftNavMenu from './containers/layout/LeftNavBar';
import NotFound from './containers/notFound';
import { useAuthDataContext } from './contexts/AuthContext';
import { login as loginUrl } from './constants/internalLinks';

function RouteManaged() {
  const currentUser = useAuthDataContext();
  const { isAuthenticated, isLoading } = currentUser;

  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <CircularProgress
            style={{
              position: 'fixed',
              width: '120px',
              height: '120px',
              top: '50%',
              left: '50%'
            }}
            color="primary"
          />
        }>
        <Routes>
          {publicRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              element={<route.component />}
            />
          ))}
          {privateRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                !isAuthenticated && !isLoading ? (
                  <Navigate to={loginUrl} replace />
                ) : (
                  <Suspense
                    fallback={
                      <CircularProgress
                        style={{
                          position: 'fixed',
                          width: '120px',
                          height: '120px',
                          top: '50%',
                          left: '50%'
                        }}
                        color="primary"
                      />
                    }>
                    <LeftNavMenu>
                      <Suspense fallback={<h5>chargement...</h5>}>
                        <route.component />
                      </Suspense>
                    </LeftNavMenu>
                  </Suspense>
                )
              }
            />
          ))}
          <Route path="/not-found" component={NotFound} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default RouteManaged;
